<template>
    <div class="row justify-content-center">
        <div class="col-md-5">
            <h3 class="text-center">Add Casket to Inventory</h3>
            <form @submit.prevent="onFormSubmit">
                <div class="form-group">
                    <label>Color</label>
                    <select class=form-control v-model="casket.color">
                                <option v-for="(color, id) in Colors" :key="id" :value="color.name">{{color.name}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="casketname" required>
                </div>
                <div class="form-group">
                    <label>Quantity</label>
                    <input type="number" class="form-control" v-model="casket.amount" required>
                </div>
                <br>
                <div class="form-group">
                    <button class="btn btn-primary btn-block">Save</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                casket: {
                },
                Colors: [],
                currentTenant: "",
                casketname: "",
                updated: false,
            }
        },
        
        created() {
            this.currentTenant = this.$tenant;
            this.casket.deleted = false;
            this.updated = false
            this.updateservices();
        },
        methods: {
            updateservices() {
                db.collection('colors').orderBy('name').onSnapshot((snapshotChange) => {
                this.Colors = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant && doc.data().deleted !== true) {
                    this.Colors.push({
                        key: doc.id,
                        name: doc.data().name,
                    })
                    }
                });
            })
            },
            setCasketDetails() {
                this.casket.nameonly = this.casketname
                this.casket.name = this.casketname + " " + this.casket.color
                if(this.casket.amount >= 1) {
                    this.casket.available = true;
                }
                else {
                    this.casket.available = false;
                }
                this.casket.tenant = this.currentTenant;  
                console.log("1", this.casket.nameonly)
            },
            doOtherStuff() {
              db.collection('caskets').orderBy('name').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    if(doc.data().name == this.casket.name && doc.data().tenant == this.currentTenant && doc.data().deleted !== true && this.updated == false) {
                        console.log("2", this.casket.nameonly)
                        db.collection('caskets').doc(doc.id)
                        .update(this.casket).then(() => {
                            console.log("Updated!");
                            this.updated = true;
                            this.$router.push('/casketlist')
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                })
              });
            },
            doMoreStuff() {
                console.log("AAA")
                if(this.updated == false) {
                    console.log("AAA2")
                    db.collection('caskets').add(this.casket).then(() => {
                        this.casket.name = ''
                        this.casket.notes = ''
                        this.$router.push('/casketlist')
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            },
            async onFormSubmit(event) {
                event.preventDefault()
                console.log("start")
                await this.setCasketDetails()
                console.log("middle")
                await this.doOtherStuff()
                await new Promise(resolve => setTimeout(resolve, 1000)); // 3 sec
                console.log("middle2")
                await this.doMoreStuff()
                console.log("Done")
            }
        }
    }
</script>